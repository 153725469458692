import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ArticleTeaser from '../components/article-teaser';
import Header from '../components/header';
import Layout from '../components/layout';
import Link from '../components/link';
import SEO from '../components/SEO';
import SectionArrowText from '../sections/section-arrow-text';
import SectionOpener from '../sections/section-opener/section-opener';
import { buildLink, resolve } from '../urls';

const CategoryOVPage = (props) => {
  const {
    data,
    location,
    pageContext,
    pageContext: { categories },
  } = props;

  const { allContentfulMagazine } = data;

  // Only use non-mutating methods like slice
  // Methods like splice() that mutate original array will affect
  // list of articles on screen even on page refresh

  const categoriesStringified = categories.toString();
  const pageMap = {
    'Article': 'article',
    'News,Events': 'news-events',
    'Insights': 'insights',
    'Interviews': 'interviews',
  };

  let allArticles;
  if (pageContext.isFirstPage) {
    allArticles = allContentfulMagazine.edges.slice(1);
  } else {
    allArticles = allContentfulMagazine.edges;
  }

  // Determine Next/Previous Buttons
  const isFirst = pageContext.currentPage === 1;
  const isLast = pageContext.currentPage === pageContext.numPages;
  const prevPage =
    pageContext.currentPage === 1
      ? `/${pageMap[categoriesStringified]}/`
      : `/${pageMap[categoriesStringified]}/page/${(pageContext.currentPage - 1).toString()}`;
  const nextPage = `/${pageMap[categoriesStringified]}/page/${(
    pageContext.currentPage + 1
  ).toString()}/`;

  const pageTitle = categories.length > 1 ? categories.join(' & ') : categories;

  const teaserType =
    categoriesStringified === 'News,Events'
      ? 'small'
      : categoriesStringified === 'Insights' || categoriesStringified === 'Interviews'
      ? 'overlay'
      : categoriesStringified === 'Article'
      ? 'regular'
      : false;

  // defines the introduction text for each category page. This will most likely replaced by CMS fields in the future
  // TODO: define and build CMS entries for category OV pages (if necessary)
  const introText =
    categoriesStringified === 'News,Events'
      ? 'Keep up to date with all the news and events around our offices in Amsterdam, Berlin, Los Angeles, Chicago, San Francisco and Singapore.'
      : categoriesStringified === 'Insights'
      ? 'We are passionate learners! Therefore we share our learnings and insights from the industries we work in and the clients we work with. Anything to add or disagree? Let us know!'
      : categoriesStringified === 'Interviews'
      ? 'Read our interviews with clients, partners and inspiring individuals to learn and grow. Because great work comes from strong partnerships, not being a supplier.'
      : categoriesStringified === 'Article'
      ? 'Dive into our thoughts and opinions around the industries we work in and what we see in the markets on the continents we work for.'
      : '';

  return (
    <div className="page--category-overview">
      <Layout>
        <SEO title={pageTitle} />

        <SectionOpener openerType="insights">
          <Header
            heading={`${pageTitle} — Page ${pageContext.currentPage}`}
            introduction={introText}
            linkText="Go back to the Magazine"
            linkPath="insights"
            reverseArrow
          />
        </SectionOpener>

        <div className="article-teaser-list article-teaser-list--paginated article-teaser-list--paginated">
          {allArticles.map(({ node }, i) => (
            <ArticleTeaser
              reading={node}
              type={teaserType}
              tall={false}
              withSubline={teaserType === 'regular'}
              noLabel
              key={i}
            />
          ))}
        </div>
        <div className="pagination">
          <div className="pagination__option">
            {!isFirst && (
              <Link to={prevPage} rel="prev" className="button-icon button-icon--reverse-arrow">
                Previous Page
              </Link>
            )}
          </div>
          {!isLast && (
            <div className="pagination__option">
              <Link to={nextPage} rel="next" className="button-icon button-icon--right">
                Next Page
              </Link>
            </div>
          )}
        </div>
        <SectionArrowText
          // simulate the data type coming from the CMS
          link={buildLink('Get in touch', resolve('pages:detail', { pageSlug: 'offices' }))}
          text={{
            childMarkdownRemark: {
              html: '<p>Are you interested in hearing more about our experience and expertise?</p>',
            },
          }}
          ctaLabel="Get in touch"
          ctaInternalLink={{ url: 'offices' }}
        />
      </Layout>
    </div>
  );
};

export default CategoryOVPage;

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!, $categories: [String!]) {
    allContentfulMagazine(
      filter: { post_category: { in: $categories }, published_date: { ne: null } }
      sort: { fields: [published_date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...allMagazinesFragments
        }
      }
    }
  }
`;

CategoryOVPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
