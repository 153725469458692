import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Image from '../components/image';
import Link from '../components/link';
import Markdown from '../components/markdown';
import { getLink } from '../urls';
import { GroupAnimation, GroupedBlockAnimation } from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const SectionArrowText = (props) => {
  const { text, ctaLabel, ctaInternalLink, link } = props;

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "espi-arrow.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
        extension
        publicURL
      }
    }
  `);

  return (
    <>
      {text && (
        <section className="arrow-text-section">
          <Image
            className="arrow-text-section__arrow-mobile"
            image={data.file.childImageSharp.gatsbyImageData}
            aria-hidden="true"
            alt=""
            role="presentation"
            objectFit="contain"
          />

          <GroupAnimation className="arrow-text-section__content">
            <GroupedBlockAnimation className="arrow-text-section__animation">
              <Markdown
                className="arrow-text-section__text"
                markdown={text.childMarkdownRemark.html}
              />
            </GroupedBlockAnimation>
            {ctaInternalLink && ctaLabel && (
              <GroupedBlockAnimation delay={DELAY_OFFSET} className="arrow-text-section__animation">
                <Link className="button arrow-text-section__cta" link={link || getLink(props)} />
              </GroupedBlockAnimation>
            )}
          </GroupAnimation>
        </section>
      )}
    </>
  );
};

export default SectionArrowText;
