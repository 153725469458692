import classNames from 'classnames';

import Markdown from './markdown';
import Link from './link';
import { GroupAnimation, GroupedTextAnimation } from './animations/group-animation';
import { DELAY_OFFSET } from './animations/config';
import { resolve } from '../urls';
import React from 'react';

const Header = (props) => {
  const {
    label,
    heading,
    introduction,
    labelStyle,
    level,
    linkText,
    linkPath,
    reverseArrow,
    headerLabelColor,
    headingItemProp,
  } = props;

  const labelClasses = classNames({
    'label': true,
    'label--invert': labelStyle === 'invert',
    'label--invert-with-red-line': labelStyle === 'invert-red',
    'label--no-line': labelStyle === 'no-line',
  });

  const headerLevel = typeof level === 'number' && level > 0 && level < 5 ? level : 1;
  const Element = `h${headerLevel}`;

  return (
    <GroupAnimation className="header">
      {label && headerLabelColor ? (
        <GroupedTextAnimation
          as="p"
          style={{ color: headerLabelColor }}
          className={labelClasses}
          delay={DELAY_OFFSET}
        >
          {label}
        </GroupedTextAnimation>
      ) : (
        <GroupedTextAnimation as="p" className={labelClasses} delay={DELAY_OFFSET * 2}>
          {label}
        </GroupedTextAnimation>
      )}

      {heading && (
        <GroupedTextAnimation as={Element} itemProp={headingItemProp} delay={DELAY_OFFSET * 3}>
          {heading}
        </GroupedTextAnimation>
      )}

      {introduction && (
        <GroupedTextAnimation className="header__text" delay={DELAY_OFFSET * 4}>
          <Markdown markdown={introduction} />
        </GroupedTextAnimation>
      )}

      {linkText && linkPath && (
        <div className="see-more__cta">
          <Link
            to={resolve('pages:detail', { pageSlug: linkPath })}
            className={`button-icon${
              reverseArrow ? ' button-icon--reverse-arrow' : ' button-icon--right'
            }`}
          >
            {linkText}
          </Link>
        </div>
      )}
    </GroupAnimation>
  );
};

export default Header;
